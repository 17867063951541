import styled from 'styled-components'

export default styled.div`
  position: relative;
  width: 200px;
  color: white;
  .drop-down-value-container {
    display: flex;
    width: 100%;
    cursor: pointer;
    padding: 1px 0px;
    border-bottom: 1px solid #fff;
    .drop-down-value {
      flex: 1;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      &.placeholder {
        width: 60px;
        color: #8a8a8a;
      }
    }
    .drop-down-icon-container {
      width: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &.disabled {
      pointer-events: none;
      border-bottom: 1px solid #4a4a4a;
      .drop-down-value {
        color: #4a4a4a;
      }
      .drop-down-icon-container {
        color: #4a4a4a;
      }
    }
    &.error {
      border-bottom: 1px solid #981214;
    }
  }
  .error-text-container {
    position: absolute;
    bottom: -18px;
    left: 0px;
    width: 100%;
    min-height: 18px;
    font-size: 12px;
    color: #eb3e42;
  }
  .dropdown-menu-outside-overlay {
    padding: 40px 0;
    padding-top: 128px;
    .dropdown-menu-overlay {
      width: 544px;
      max-height: calc(100vh - 208px); /* - 80px come from padding-top 168 and bottom 40 */
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .overlay-title-container {
        margin-bottom: 8px;
      }
      .input-dropdown-wrapper {
        position: relative;
        .input-dropdown {
          font-family: 'Prompt', sans-serif;
          width: 100%;
          font-size: 18px;
          border: solid 1px #660c0d;
          outline: none;
          padding: 5px 18px;
          padding-right: 40px;
          background-color: #1e1e1e;
          color: white;
          &:disabled {
            color: #4a4a4a;
            ::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #4a4a4a;
            }
            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4a4a4a;
            }
          }
          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-family: 'Prompt', sans-serif;
            color: #8a8a8a;
            opacity: 1; /* Firefox */
          }
          ::-ms-input-placeholder {
            /* Microsoft Edge */
            font-family: 'Prompt', sans-serif;
            color: #8a8a8a;
          }
        }
        .clear-search-input {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 8px;
          top: 8px;
          cursor: pointer;
        }
      }
      .dropdown-menu-wrapper {
        margin-top: 16px;
        flex: 1;
        overflow: auto;
        .drop-down-menu {
          font-size: 18px;
          padding: 13px 18px;
          border: solid 1px #660c0d;
          border-bottom: none;
          background-color: #1e1e1e;
          transition: .2s;
          color: white;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            background-color: #660c0d;
          }
          &:last-child {
            border-bottom: solid 1px #660c0d;
            }
          }
        }
        .drop-down-not-found {
          background-color: #1e1e1e;
          padding: 110px 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 16px;
          }
          .detail {
            font-size: 16px;
          }
        }
      }
    }
  }
`
