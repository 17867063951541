import React from 'react'
import PropTypes from 'prop-types'
import DownIconSrc from '../../assets/images/collapse/dropdown-down.svg'
import ClearIcon from '../../assets/images/dropdown/unselect.svg'

import Label from '../Label/dynamic'
import Overlay from '../Overlay/dynamic'

import { withTranslation } from '../../../i18n'

import DropdownStyled from './styled'

export class Dropdown extends React.PureComponent {
  state = {
    searchValue: '',
    showDropdownMenu: false,
    isSearching: false,
  }

  getLabel() {
    let output = null
    if (this.props.label) {
      output = <Label id={`${this.props.id}-label`} text={this.props.label} disabled={this.props.disabled} />
    }
    return output
  }

  getDropdownlabel() {
    let output = null
    if (this.props.dropdownLabel) {
      output = this.props.dropdownLabel
    }
    return output
  }

  handleDropdownMenuClicked = optionData => {
    this.props.onChange(optionData.value)
    this.handleHideDropdownMenu()
  }

  searchSubsequence = (inputValue, listValue) => {
    if (!!inputValue && inputValue.charAt(0) !== listValue.charAt(0)) {
      return false
    }

    let pointer1 = 0
    let pointer2 = 0

    while (pointer1 < inputValue.length && pointer2 < listValue.length) {
      if (listValue.charAt(pointer2) === inputValue.charAt(pointer1)) pointer1 += 1
      pointer2 += 1
    }

    return pointer1 === inputValue.length
  }

  getOverlayOptionMenu() {
    let availableListOptions = this.props.options
    if (this.state.isSearching) {
      availableListOptions = this.props.options.filter(optionData =>
        this.searchSubsequence(this.state.searchValue.toLowerCase(), optionData.text.toLowerCase()),
      )
    }
    let outputMenu = (
      <div id={`${this.props.id}-not-found-menu`} data-test={`${this.props.id}-not-found-menu`} className="drop-down-not-found">
        <div className="title">{this.props.t('no_results_found')}</div>
        <div className="detail">{this.props.t('we_cant_find_any_item_matching_your_search')}</div>
      </div>
    )
    if (availableListOptions.length > 0) {
      outputMenu = availableListOptions.map(optionData => {
        return (
          <div
            key={optionData.value}
            id={`${this.props.id}-menu-${optionData.value}`}
            data-test={`${this.props.id}-menu-${optionData.value}`}
            className={'drop-down-menu'}
            onClick={() => this.handleDropdownMenuClicked(optionData)}
          >
            {optionData.text}
          </div>
        )
      })
    }
    let output = null
    if (this.state.showDropdownMenu) {
      const searchInput = (
        <div className="clear-search-input" onClick={this.clearInputSearch}>
          <img src={ClearIcon} />
        </div>
      )
      output = (
        <Overlay
          id={`${this.props.id}-dropdown-menu-overlay`}
          className="dropdown-menu-overlay"
          overlayClassName="dropdown-menu-outside-overlay"
          title={this.getDropdownlabel()}
          onClose={this.handleHideDropdownMenu}
        >
          <div className="input-dropdown-wrapper">
            <input
              id={`${this.props.id}-search-input`}
              data-test={`${this.props.id}-search-input`}
              className="input-dropdown"
              type="text"
              placeholder={this.props.placeholder}
              value={this.state.searchValue}
              onChange={this.handleSearchInputChanged}
              disabled={this.props.disabled}
              autoComplete="off"
            />
            {this.state.searchValue !== '' ? searchInput : null}
          </div>

          <div className="dropdown-menu-wrapper">{outputMenu}</div>
        </Overlay>
      )
    }
    return output
  }

  clearInputSearch = () => {
    this.setState({
      searchValue: '',
    })
  }

  handleHideDropdownMenu = () => {
    this.setState({
      showDropdownMenu: false,
      searchValue: this.props.options.find(optionData => optionData.value === this.props.value)?.text || '',
      isSearching: false,
    })
  }

  toggleDropdownMenu = () => {
    const selectedOption = this.props.options.find(optionData => optionData.value === this.props.value)
    this.setState({
      showDropdownMenu: !this.state.showDropdownMenu,
      searchValue: selectedOption?.text || '',
    })
  }

  handleSearchInputChanged = e => {
    this.setState({
      searchValue: e.target.value,
      isSearching: true,
    })
  }

  getCurrentValue() {
    const iconArrow = DownIconSrc
    const selectedOption = this.props.options.find(optionData => optionData.value === this.props.value)
    let className = 'drop-down-value-container'
    className += this.props.disabled ? ' disabled' : ''
    className += this.props.errorText ? ' error' : ''
    const text = selectedOption?.text || this.props.placeholder
    let classNameValue = 'drop-down-value'
    classNameValue += selectedOption ? '' : ' placeholder'
    const currentValue = (
      <div id={`${this.props.id}-value`} data-test={`${this.props.id}-value`} className={classNameValue}>
        {text}
      </div>
    )
    return (
      <div id={`${this.props.id}-value-box`} data-test={`${this.props.id}-value-box`} className={className} onClick={this.toggleDropdownMenu}>
        {currentValue}
        <div id={`${this.props.id}-option-box`} data-test={`${this.props.id}-option-box`} className="drop-down-icon-container">
          <img src={iconArrow} />
        </div>
      </div>
    )
  }

  getError() {
    return (
      <div id={`${this.props.id}-error-txt`} data-test={`${this.props.id}-error-txt`} className="error-text-container">
        {this.props.errorText}
      </div>
    )
  }

  render() {
    return (
      <DropdownStyled
        id={this.props.id}
        data-test={this.props.id}
        ref={ref => {
          this.dropdown = ref
        }}
        className={this.props.className}
      >
        {this.getLabel()}
        {this.getCurrentValue()}
        {this.getError()}
        {this.getOverlayOptionMenu()}
      </DropdownStyled>
    )
  }
}

Dropdown.defaultProps = {
  id: 'drop-down',
  label: '',
  placeholder: '',
  options: [],
  disabled: false,
  searchable: false,
  errorText: '',
}

Dropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  dropdownLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
}

export default withTranslation()(Dropdown)
